export const ROUTE_PATHS = {
  EditOrAddNewTopic: "/programs/:program_id/topic/:mode/:id?",
  DetialsTopic: "/getprogra/:program_id/topics/:id",
  DirectorDetialsTopic: "/programs/getprogra/:program_id/topics/:id",
  FinalTest: "/FinalTest",
  programsFinalTest: "/programs/FinalTest",
  authorsFinalTest: "/authors/FinalTest",
  FinalTestScore: "/FinalTestScore",
  // OldTopic: "/programs/OldTopic",
  Topics: "/programs/Topics",
  StudentsTopics: "/Topics",
  StudentProgress: "/programs/StudentProgress",
  StudentsList: "/programs/StudentsList",
  home: "/",
  authorsHome: "/authors",
  editorsHome: "/editors",
  login: "/login",
  programHome: "/programs",
  programsLogin: "/programs/login",
  authorLogin: "/authors/login",
  editorLogin: "/editors/login",
  missingVerification: "/missing_verification",
  verification: "/verification",
  registration: "/registration",
  forgetPassword: "/forget-password",
  bookProfile: "/book-profile",
  programBookProfile: "/program/book-profile",
  books: "/books",
  bookViewer: "/book-viewer",
  authorsBookViewer: "/authors/book-viewer",
  editorBookViewer: "/editors/book-viewer",
  programsBookViewer: "/programs/book-viewer",
  subscriptionManagement: "/subscription-management",
  userBookList: "/subscriped-books",
  checkout: "/checkout",
  // bookAllSupplementary: "/book-all-supplementaries",
  bookAllVideos: "/book-all-videos",
  bookAllTests: "/book-all-tests",
  programsBookAllVideos: "/programs/book-all-videos",
  programsBookAllTests: "/programs/book-all-tests",
  authorsBookAllVideos: "/authors/book-all-videos",
  authorsBookAllTests: "/authors/book-all-tests",
  supplementary: "/supplementary",
  authorsSupplementary: "/authors/supplementary",
  programsSupplementary: "/programs/supplementary",
  profileInfo: "/profile-info",
  changePassword: "/change-password",
  authorsChangePassword: "/authors/change-password",
  editorsChangePassword: "/editors/change-password",
  programsChangePassword: "/programs/change-password",
  contactUs: "/contact-us",
  certificates: "/certificates",
  institutionBooks: "/institution-books",
  promocode: "/promocode",
  bookCheckout: "/bookCheckout",
  testdisplay: "/TestDisplay",
  quizdisplay: "/QuizDisplay",
  programsTestDisplay: "/programs/TestDisplay",
  programsQuizDisplay: "/programs/QuizDisplay",
  authorsTestDisplay: "/authors/TestDisplay",
  authorsQuizDisplay: "/authors/QuizDisplay",
  signin: "/SignIn",
  editorNotes: "/editors/notes"
};

import { put, call } from "redux-saga/effects";
import messages from "../../assets/locale/messages";
import * as apis from "../../network/apis/auth";
import { browserName } from "react-device-detect";
import { v4 as uuidv4 } from "uuid";

import { showHideSnackbar } from "../Snackbar/actions";
import {
  loginResponse,
  completeDataWithGoogle,
  toggleLogin
} from "./actions";
import { setCustomer } from "./helpers";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import History from "../../routes/History";
import {
  setSubscibedBooksAction,
  setSubscribedBooksIds
} from "../Books/actions";

export function* loginSaga({ payload }) {
  const auth = {
    email: payload.email,
    password: payload.password,
    device_id: browserName + "-" + uuidv4(),
    fcm_token: null
  };
  try {
    let response = {};
    if (payload.isAuthor) {
      response = yield call(apis.loginAuthor, auth);
    } else if (payload.isEditor)
      response = yield call(apis.loginEditor, auth);
    else if (payload.isProgram)
      response = yield call(apis.loginProgramDirector, auth);
    else response = yield call(apis.login, auth);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].shared.welcomeMessage
      })
    );
    let loggedUser;
    (() => {
      if (payload.isAuthor) loggedUser = response.data.author;
      else if (payload.isEditor) loggedUser = response.data.editor;
      else if (payload.isProgram)
        loggedUser = response.data.programDirector;
      else loggedUser = response.data.user;
    })();
    yield put(loginResponse(loggedUser));
    setCustomer(response.data, "local");
    if (
      !payload.isAuthor &&
      !payload.isEditor &&
      !payload.isProgram
    ) {
      response.data.user.email_verified
        ? History.push("/")
        : History.push(ROUTE_PATHS.missingVerification);
    }
    if (payload.isAuthor && response.data.author) {
      History.push("/authors");
    }
    if (payload.isEditor && response.data.editor) {
      History.push("/editors");
    }
    if (payload.isProgram && response.data.programDirector) {
      History.push("/programs");
    }
  } catch ({ response }) {
    //
  }
}

export function* getDetails() {
  try {
    const response = yield call(apis.userDetails);
    yield put(loginResponse(response.data.user));

    const localOldUser = JSON.parse(localStorage.getItem("user"));
    /* WHY THIS BAD CODE ? */
    /* Because there is legacy bad code that forced me to do so */
    /* Advice: We should commit to forms that are error resistant */
    if (localOldUser?.id) {
      if (!response.data.user) {
        delete localOldUser.institution_subscription;
        delete localOldUser.is_beta;
      }

      setCustomer(
        {
          user: {
            auth_token: localOldUser.auth_token,
            ...response.data.user
          }
        },
        "local"
      );
      yield put(
        loginResponse({
          auth_token: localOldUser.auth_token,
          ...response.data.user
        })
      );
    }
  } catch ({ response }) {
    //
  }
}

export function* loginThenVerify({ payload }) {
  const { clonedData } = payload;
  console.log("login then verify payload Saga ", payload);
  console.log("login then verify dataa Saga ", clonedData);

  const auth = {
    email: clonedData.email,
    password: clonedData.password,
    device_id: browserName + "-" + uuidv4(),
    fcm_token: null
  };
  try {
    const response = yield call(apis.login, auth);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].shared.welcomeMessage
      })
    );
    localStorage.removeItem("token"); // to prevent auto verify in containers/verification
    yield put(loginResponse(response.data.user));
    yield payload.rememberMe
      ? setCustomer(response.data, "local")
      : setCustomer(response.data, "session");
    const verificationResponse = yield call(apis.verifyUser, {
      email_verification_token: payload.token
    });

    yield put(
      loginResponse({ ...response.data.user, email_verified: true })
    );
    yield payload.rememberMe
      ? setCustomer(
          { user: { ...response.data.user, email_verified: true } },
          "local"
        )
      : setCustomer(
          { user: { ...response.data.user, email_verified: true } },
          "session"
        );
    History.push("/");
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[verificationResponse.lang].signup
            .verificationSuccessMessage
      })
    );
  } catch ({ response }) {
    //
  }
}

/**
 *
 * @generator
 * @export
 * @param {*} { login => main login payload, type => "signin" | "signup", profile => google user data }
 */
export function* authorizeViaGoogle({ payload }) {
  try {
    const response = yield call(apis.socialAuthorization, {
      ...payload,
      device_id: browserName + "-" + uuidv4(),
      fcm_token: null
    });
    const { shared } = messages[response.lang];
    yield put(loginResponse(response.data.user));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: shared.welcomeMessage
      })
    );

    setCustomer(response.data, "local");

    yield put(completeDataWithGoogle(null));

    History.push("/");
  } catch ({ response }) {
    if (response.data.message) {
      yield put(
        showHideSnackbar({
          isOpen: true,
          type: "error",
          message: response.data.message
        })
      );
    }
    if (payload.type === "signin") {
      yield put(completeDataWithGoogle(payload.profile));
      yield put(toggleLogin());
      History.push(ROUTE_PATHS.registration);
    } else {
      yield put(completeDataWithGoogle(payload.profile));
    }
  }
}

export function* newUserRegistration({ payload }) {
  try {
    const response = yield call(apis.register, {
      ...payload,
      device_id: browserName + "-" + uuidv4(),
      fcm_token: null
    });
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].signup.successMessage
      })
    );
    yield put(loginResponse(response.data.user));
    setCustomer(response.data, "local");

    History.push("/");
  } catch ({ response }) {
    //
  }
}

export function* editUserInfo({ payload }) {
  try {
    const response = yield call(apis.editUserInfo, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].editUserInfo.successMessage
      })
    );
    yield put(loginResponse(response.data.user));

    const localOldUser = JSON.parse(localStorage.getItem("user"));

    /* WHY THIS BAD CODE ? */
    /* Because there is legacy bad code that forced me to do so */
    /* Advice: We should commit to forms that are error resistant */
    if (localOldUser?.id) {
      setCustomer(
        { user: { ...localOldUser, ...response.data.user } },
        "local"
      );
      yield put(
        loginResponse({ ...localOldUser, ...response.data.user })
      );
    }

    History.push("/");
  } catch ({ response }) {
    //
  }
}

export function* changePassword({ payload }) {
  let api = apis.changePassword;
  if (payload.isAuthor) api = apis.changeAuthorsPassword;
  if (payload.isEditor) api = apis.changeEditorsPassword;
  if (payload.isProgram) api = apis.changeProgramDirectorsPassword;
  try {
    const response = yield call(api, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: messages[response.lang].changePassword.successMessage
      })
    );
    if (payload.isAuthor) History.push(ROUTE_PATHS.authorsHome);
    if (payload.isEditor) History.push(ROUTE_PATHS.editorsHome);
    if (payload.isProgram) History.push(ROUTE_PATHS.programHome);
    else History.push(ROUTE_PATHS.home);
  } catch ({ response }) {
    //
  }
}

export function* restNewPasswordSaga({ payload }) {
  try {
    const response = yield call(apis.restNewPassword, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: response.data.message
      })
    );
    // yield put(loginResponse(response2.data.user));
    History.push(ROUTE_PATHS.home);
  } catch ({ response }) {
    //
  }
}

export function* sendEmailNewPasswordSaga({ payload }) {
  try {
    const response = yield call(apis.sendEmailNewPassword, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: response.data.message
      })
    );
    History.push(ROUTE_PATHS.home);
  } catch ({ response }) {
    //
  }
}

export function* logoutSaga({ payload }) {
  let logoutApi = apis.logout;
  if (payload.isAuthor) logoutApi = apis.authorLogout;
  if (payload.isEditor) logoutApi = apis.editorLogout;
  if (payload.isProgram) logoutApi = apis.programLogout;
  try {
    yield call(logoutApi);
    yield put(setSubscibedBooksAction([]));
    yield put(setSubscribedBooksIds([]));
    localStorage.removeItem("user");
    let homePath = ROUTE_PATHS.home;
    if (payload.isAuthor) homePath = ROUTE_PATHS.authorsHome;
    if (payload.isEditor) homePath = ROUTE_PATHS.editorsHome;
    if (payload.isProgram) homePath = ROUTE_PATHS.programHome;
    History.push(homePath);
    yield put(loginResponse({}));
  } catch (err) {
    console.error(err);
  }
}

export function* verifyUser({ payload }) {
  try {
    const response = yield call(apis.verifyUser, payload);
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].signup.verificationSuccessMessage
      })
    );
    const localOldUser = JSON.parse(localStorage.getItem("user"));
    setCustomer(
      { user: { ...localOldUser, ...response.data.user } },
      "local"
    );
    yield put(
      loginResponse({ ...localOldUser, ...response.data.user })
    );
    History.push("/");
  } catch ({ response }) {
    //
  }
}

export function* resendVerificationEmail({ payload }) {
  try {
    const response = yield call(
      apis.resendVerificationEmail,
      payload
    );
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message:
          messages[response.lang].signup.verificaitonEmailResent
      })
    );
  } catch ({ response }) {
    //
  }
}

import { put, call } from "redux-saga/effects";
import * as apis from "../../network/apis/books";
import _ from "lodash";
import {
  setBookQuizzes,
  setBooksAction,
  setBooksFeaturedAction,
  setCertainQuiz,
  setRelatedBooksAction,
  setSingleBookAction,
  setSingleBookSupplementariesAction,
  setBookSupplementaryProgress,
  setSubscibedBooksAction,
  setSubscribedBooksIds,
  getSubscribedBooksResponse,
  getBookSingleTestResponse,
  getBookTestsResponse,
  getcertificatetsResponse,
  getInstitutionBooksResponse,
  getSingleBookAction,
  setEditorNotes,
  setUserProgramAction,
  getSingleCertificateResponce
} from "./actions";
import { metaObject } from "../../utils/general";
import { showHideSnackbar } from "../Snackbar/actions";
import Auth from "../../utils/Auth";
import { logDOM } from "@testing-library/react";

export function* getBooksSaga({ payload }) {
  if (payload.isProgram) {
    // return;
  }
  const authorApi = Auth.getAuth()
    ? apis.getAuthorAssignedBook
    : apis.getAuthorBooks;
  const editorApi = Auth.getAuth()
    ? apis.getEditorAssignedBook
    : apis.getEditorBooks;
  const api = payload.isAuthor
    ? authorApi
    : payload.isEditor
    ? editorApi
    : apis.getBooks;
  try {
    const response = yield call(
      api,
      _.omit(payload, ["list", "isLoadMore"])
    );
    const action = payload.is_featured
      ? setBooksFeaturedAction
      : setBooksAction;
    const shouldConcat = !!payload.isLoadMore;
    yield put(
      action({
        list: shouldConcat
          ? [...payload.list, ...response.data.books]
          : response.data.books,
        meta: metaObject(response)
      })
    );
    if (payload.isLoadMore) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  } catch (err) {
    console.error(err);
  }
}

export function* getUserProgram({ payload }) {
  try {
    const response = yield call(apis.getUserProgram, payload);
    yield put(
      setUserProgramAction({ programs: response.data.programs })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getRelatedBooksSaga({ payload }) {
  const api = payload.isAuthor
    ? apis.getAuthorRelatedBooks
    : apis.getRelatedBooks;
  try {
    const response = yield call(api, {
      ...payload,
      params: _.omit(payload.params, ["list", "isLoadMore"])
    });
    const shouldConcat = !!payload.params.isLoadMore;
    yield put(
      setRelatedBooksAction({
        list: shouldConcat
          ? [...payload.params.list, ...response.data.books]
          : response.data.books,
        meta: metaObject(response)
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getSingeBookSaga({ payload }) {
  try {
    const response = yield call(apis.getSingleBook, payload);
    yield put(setSingleBookAction(response.data.book));
  } catch (err) {
    console.error(err);
  }
}

export function* getAuthorSingeBookSaga({ payload }) {
  try {
    const response = yield call(apis.getAuthorSingleBook, payload);
    yield put(setSingleBookAction(response.data.books));
  } catch (err) {
    console.error(err);
  }
}

export function* getEditorSingleBookSaga({ payload }) {
  try {
    const response = yield call(apis.getEditorSingleBook, payload);
    yield put(setSingleBookAction(response.data.books));
  } catch (err) {
    console.error(err);
  }
}

export function* getDirectorSingleBookSaga({ payload }) {
  try {
    const response = yield call(apis.getDirectorSingleBook, payload);
    yield put(setSingleBookAction(response.data?.data?.book));
    // yield put(
    //   setSingleBookSupplementariesAction(
    //     response.data?.data?.supplementary_materials
    //   )
    // );
  } catch (err) {
    console.error(err);
  }
}

export function* getAuthorAssignedBookSaga({ payload }) {
  try {
    const response = yield call(apis.getAuthorAssignedBook, payload);
    yield put(
      setBooksAction({
        list: response.data.books,
        meta: metaObject(response)
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* getEditorAssignedBookSaga() {
  try {
    const response = yield call(apis.getEditorAssignedBook);
    yield put(
      setBooksAction({
        list: response.data.books,
        meta: metaObject(response)
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getSingeBookSupplementariesSaga({ payload }) {
  try {
    const { isAuthor, isProgram } = payload;
    const {
      data: { supplementary_materials }
    } = yield call(
      isAuthor
        ? apis.getAuthorSingleBookSupplementaries
        : isProgram
        ? apis.getDirectorSingleBookSupplementaries
        : apis.getSingeBookSupplementaries,
      { book_id: payload.book_id }
    );

    yield put(
      setSingleBookSupplementariesAction(supplementary_materials)
    );
  } catch (err) {
    console.error(err);
  }
}

export function* UnubscribeBook({ payload }) {
  try {
    yield call(apis.unsubscribeBook, payload);
    const response = yield call(apis.getSubscribedBooks);
    yield put(
      setSubscibedBooksAction(response.data.book_subcription)
    );
    yield put(
      setSubscribedBooksIds(
        response.data.book_subcription.map(
          (subscription) => subscription.book.id
        )
      )
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getSubscribedBooks({ payload }) {
  try {
    const response = yield call(apis.getSubscribedBooks, payload);
    const { data } = response;
    yield put(setSubscibedBooksAction(data.book_subcription));
    yield put(
      setSubscribedBooksIds(
        data.book_subcription.map(({ book }) => book.id)
      )
    );
    yield put(
      getSubscribedBooksResponse({
        list: data.book_subcription,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        },
        subscibedBooksMeta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* updateBookProgress({ payload }) {
  try {
    const response = yield call(apis.updateBookProgress, {
      id: payload.id,
      page: payload.page,
      ...payload
    });

    // yield put(
    //   setSingleBookAction({
    //     ...payload.singleBook,
    //     book_progress: {
    //       ...payload.singleBook,
    //       page: payload.page
    //     }
    //   })
    // );
    yield put(getSingleBookAction(payload.id));
    yield put(
      showHideSnackbar({
        isOpen: true,
        type: "success",
        message: response.data.message
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* updateBookSupplementaryProgress({ payload }) {
  try {
    const response = yield call(
      apis.updateBookSupplementaryProgress,
      {
        book_id: payload.book_id,
        supplementary_material_id: payload.supplementary_material_id,
        watched: payload.watched
      }
    );

    yield put(getSingleBookAction(payload.book_id));
  } catch (err) {
    console.error(err);
  }
}

export function* getBookSupplementaryProgress({ payload }) {
  try {
    const response = yield call(
      apis.getBookSupplementaryProgress,
      payload
    );

    yield put(
      setBookSupplementaryProgress(
        response.data.supplementary_progress
      )
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getBookQuizzes({ payload }) {
  const { isAuthor, isProgram } = payload;

  const API = isAuthor
    ? apis.getBookQuizzesForAuthors
    : isProgram
    ? apis.getBookQuizzesForDirectors
    : apis.getBookQuizzes;
  try {
    const response = yield call(API, {
      page: payload.page,
      book_id: payload.book_id
    });

    yield put(setBookQuizzes(response.data.book_quiz));
  } catch (err) {
    console.error(err);
  }
}
export function* getCertainQuiz({ payload }) {
  const { isAuthor, isProgram } = payload;
  const API = isAuthor
    ? apis.getCertainQuizForAuthors
    : isProgram
    ? apis.getCertainQuizForDirector
    : apis.getCertainQuiz;
  try {
    const response = yield call(API, payload);

    yield put(setCertainQuiz(response.data.quiz));
    // yield put(
    //   showHideSnackbar({
    //     isOpen: true,
    //     type: "success",
    //     message: response.data.message
    //   })
    // );
  } catch (err) {
    console.error(err);
  }
}

export function* getBookTestsSaga({ payload }) {
  const { isAuthor, isProgram } = payload;
  const API = isAuthor
    ? apis.getBookTestsForAuthor
    : isProgram
    ? apis.getBookTestsForDirector
    : apis.getBookTests;
  try {
    const response = yield call(API, payload);
    yield put(getBookTestsResponse(response.data.tests));
  } catch (err) {
    console.error(err);
  }
}

export function* getBookSingleTestSaga({ payload }) {
  const { isAuthor, isProgram } = payload;

  const API = isAuthor
    ? apis.getBookSingleTestForAuthor
    : isProgram
    ? apis.getBookSingleTestForDirector
    : apis.getBookSingleTest;
  try {
    const response = yield call(API, payload);

    yield put(getBookSingleTestResponse(response.data.test));
  } catch (err) {
    console.error(err);
  }
}

export function* answerBookTestSaga({ payload }) {
  try {
    const response = yield call(apis.answerBookSingleTest, payload);

    yield put(getBookSingleTestResponse(response.data.test));
  } catch (err) {
    console.error(err);
  }
}

export function* getCertificateList({ payload }) {
  // console.log("getCertificateList payload", payload);

  try {
    const response = yield call(apis.getCertificateList, payload);
    const { data } = response;
    yield put(
      getcertificatetsResponse({
        list: data.certificates,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getSinlgeCertificateSaga({ payload }) {
  try {
    const response = yield call(apis.getSinlgeCertificate, payload);
    console.log("getSinlgeCertificate responce:", response);
    const { data } = response;

    yield put(
      getSingleCertificateResponce({
        singleCertificate: data.certificate
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* getInstitutionBooksList({ payload }) {
  try {
    const response = yield call(
      apis.getInstitutionBooksList,
      payload
    );
    const { data } = response;
    yield put(
      getInstitutionBooksResponse({
        list: data.books,
        meta: {
          currentPage: +response.headers["current-page"],
          count: +response.headers["total-count"],
          pages: +response.headers["total-pages"]
        }
      })
    );
  } catch (err) {
    console.error(err);
  }
}

export function* getEditorNotesByBookSaga({ payload }) {
  try {
    const response = yield call(apis.getEditorNotesByBook, payload);
    const { data } = response;
    yield put(
      setEditorNotes({
        notes: data?.annotations
      })
    );
  } catch (err) {
    console.error(err);
  }
}
export function* getDirectorNotesByBookSaga({ payload }) {
  try {
    const response = yield call(apis.getDirectorNotesByBook, payload);
    const { data } = response;
    yield put(
      setEditorNotes({
        notes: data?.annotations
      })
    );
  } catch (err) {
    console.error(err);
  }
}

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./Button.module.scss";

const Button = ({
  text,
  onClick,
  className,
  isLink,
  slug,
  type,
  disabled,
  ...props
}) => {
  return !isLink ? (
    <button
      className={`${styles[className]} ${styles.button} ${className}`}
      onClick={onClick}
      type={type}
      {...props}
      disabled={disabled}
    >
      {text}
    </button>
  ) : (
    <Link
      className={`${styles[className]} ${styles.button} ${className}`}
      onClick={onClick}
      to={slug}
    >
      {text}
    </Link>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isLink: PropTypes.bool,
  slug: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  className: "primary",
  text: "click here",
  onClick: () => {},
  isLink: false,
  disabled: false,
  slug: "#"
};

export default Button;

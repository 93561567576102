import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { Box, Typography, Modal } from "@mui/material";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { jsPDF } from "jspdf";

import { writtenBy } from "../../../utils/general";
import CertificateMedblend from "../../../assets/images/Medblendcertificate1.svg";
import CertificateCME from "../../../assets/images/Certificate-CME.svg";
import Button from "../../../components/Button";
import { Card as BootstrapCard } from "../../../components/Card/Card";
import styles from "./CertificatesList.module.scss";
import { boxStyle } from "../BookViewer/boxStyle";

const CertificatesList = ({ certificatesList, title, btn_text }) => {
  const handleCloseCertificate = () => setOpenCertificateModal(false);
  const [currentCertificate, setCurrentCertificate] = useState();
  const canvasRef = useRef(null);
  const [openCertificateModal, setOpenCertificateModal] =
    useState(false);

  const { locale } = useIntl();
  useEffect(() => {
    if (openCertificateModal) {
      setTimeout(() => {
        if (currentCertificate.certificate.hours_of_participation) {
          displayCertificateCME();
        } else {
          displayCertificate();
        }
      }, 1000);
    }
  }, [openCertificateModal, currentCertificate]);

  const displayCertificateCME = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Adjust canvas resolution for better quality
    const dpr = window.devicePixelRatio || 1;
    const canvasWidth = 700; // Desired width in CSS pixels
    const canvasHeight = 500; // Desired height in CSS pixels

    canvas.width = canvasWidth * dpr;
    canvas.height = canvasHeight * dpr;
    canvas.style.width = `${canvasWidth}px`;
    canvas.style.height = `${canvasHeight}px`;

    ctx.scale(dpr, dpr);

    const usersName = currentCertificate?.user_name;
    // user?.first_name + " " + user?.second_name || "";
    const bookName = currentCertificate?.book_title || "";
    const submissionDate =
      currentCertificate?.certificate?.submission_date;
    const SubmissionEdit = submissionDate
      .split("T")[0]
      .split("-")
      .reverse()
      .join("/");
    const issueDate = currentCertificate?.issue_date;
    const issueDateEdited = issueDate
      ?.split("T")[0]
      .split("-")
      .reverse()
      .join("/");
    const certificateID =
      currentCertificate?.certificate?.certificate_unique_id;
    const hours =
      currentCertificate?.certificate?.hours_of_participation;
    const image = new Image();
    image.src = CertificateCME;

    image.onload = () => {
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);

      // Set font style for username
      ctx.font = "40px Inter";
      ctx.fillStyle = "#000000";
      ctx.textAlign = "center";

      const canvasCenterX = canvasWidth / 2;

      // Break long names into two lines
      if (usersName.length > 20) {
        const maxLineLength = 21;
        const breakIndex = usersName.lastIndexOf(" ", maxLineLength);

        // First line of name
        const firstLine = usersName.slice(0, breakIndex);
        ctx.fillText(firstLine, canvasCenterX, 135);

        // Second line of name
        const secondLine = usersName.slice(breakIndex + 1);
        ctx.fillText(secondLine, canvasCenterX, 170);
      } else {
        ctx.fillText(usersName, canvasCenterX, 150);
      }

      // Book name
      ctx.font = "10px sans-serif";
      if (bookName.length > 63) {
        const maxBookLineLength = 64;
        const bookBreakIndex = bookName.lastIndexOf(
          " ",
          maxBookLineLength
        );

        const firstBookLine = bookName.slice(0, bookBreakIndex);
        ctx.fillText(firstBookLine, canvasCenterX, 190);

        const secondBookLine = bookName.slice(bookBreakIndex + 1);
        ctx.fillText(secondBookLine, canvasCenterX, 195);
      } else {
        ctx.fillText(bookName, canvasCenterX, 205);
      }
      //SUBMISSION DATE
      ctx.font = "8px sans-serif";
      ctx.fillText(SubmissionEdit, canvasCenterX + 55, 217);
      // CME HOURS
      ctx.font = "8px sans-serif";
      ctx.fillText(hours, canvasCenterX + 46, 230.3);
      //CME HOURS AT DISCRIPTION
      ctx.font = "8px sans-serif";
      ctx.fillText(hours, canvasCenterX + 112, 300);
      //ISSUE DATE
      ctx.fillStyle = "#6c6b68";
      ctx.font = "8px sans-serif";
      ctx.fillText(issueDateEdited, canvasCenterX - 86, 456);
      //CERTIFICATE ID
      ctx.font = "8px sans-serif";
      ctx.fillText(certificateID, canvasCenterX + 146, 455);
    };

    image.onerror = () => {
      console.error("Failed to load the certificate image.");
    };
  };

  const displayCertificate = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const usersName = currentCertificate?.user_name;
    const bookName = currentCertificate?.book_title;
    const bookauthor = currentCertificate?.author_name;
    //for best resolution
    console.log("window.devicePixelRatio", window.devicePixelRatio);

    const dpr = window.devicePixelRatio || 1;
    const canvasWidth = 700;
    const canvasHeight = 500;
    canvas.width = canvasWidth * dpr;
    canvas.height = canvasHeight * dpr;
    canvas.style.width = `${canvasWidth}px`;
    canvas.style.height = `${canvasHeight}px`;
    const canvasCenterX = canvasWidth / 2;
    ctx.scale(dpr, dpr);

    const image = new Image();
    image.src = CertificateMedblend;
    image.onload = () => {
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
      ctx.textAlign = "center";
      ctx.font = "25px  sans-serif";
      ctx.fillStyle = "#464B55";
      // title
      const demotitle =
        currentCertificate?.certificate?.certificate_title;
      ctx.fillText(demotitle, canvasCenterX, 155);
      // presnst to
      ctx.font = "17px  monospace";
      ctx.textAlign = "center";

      const demoToUserHint =
        "This certificate is proudly presented to";

      ctx.fillText(demoToUserHint, canvasCenterX, 190);
      //username
      ctx.font = "bold italic 30px Arial";
      ctx.fillStyle = "#0e4dce";

      ctx.fillText(usersName, canvasCenterX, 225);
      // Book
      ctx.font = "12px  Arial";
      ctx.fillStyle = "#464B55";

      ctx.fillText("For Completing", canvasCenterX, 255);
      ctx.font = "17px Arial";
      ctx.fillStyle = "#0e4dce";

      // separate bookname to 2 line
      if (bookName?.length > 63) {
        const demobookNameindex = bookName.lastIndexOf(" ", 64);
        ctx.fillText(bookName.slice(0, demobookNameindex), 160, 275);
        ctx.fillText(
          bookName.slice(demobookNameindex + 1, bookName.length),
          canvasCenterX,
          295
        );
        ctx.font = "italic 10px  Arial";
        ctx.fillStyle = "#464B55";
        ctx.fillText(`   by ${bookauthor}`, 170, 310);
      } else {
        ctx.fillText(bookName, canvasCenterX, 275);
        ctx.font = "italic 10px  Arial";
        ctx.fillStyle = "#464B55";
        ctx.fillText(`by ${bookauthor}`, canvasCenterX, 295);
      }

      // const creditHours = singleBook?.certificate.credits;
      const creditType =
        currentCertificate?.certificate?.certificate_type;

      ctx.font = "12px  Arial";
      ctx.fillStyle = "#2e2e2e";

      ctx.fillText(
        `and has been awarded ${creditType} credits `,
        canvasCenterX,
        370
      );

      // description
      ctx.font = " 12px  Arial";
      ctx.fillStyle = "#5e5e5e";

      const certificateDescription =
        currentCertificate?.certificate.certificate_description;
      if (certificateDescription?.length > 70) {
        const demoDescription1index =
          certificateDescription.lastIndexOf(" ", 71);
        ctx.fillText(
          certificateDescription.slice(0, demoDescription1index),
          canvasCenterX,
          330
        );
        ctx.fillText(
          certificateDescription.slice(
            demoDescription1index + 1,
            certificateDescription.length
          ),
          canvasCenterX,
          350
        );
      } else {
        ctx.fillText(certificateDescription, canvasCenterX, 330);
      }
    };
    image.onerror = () => {
      console.error("Failed to load the certificate image.");
    };
  };

  const downloadCertificate = () => {
    const canvas = canvasRef.current;

    if (!canvas) {
      console.error("Canvas reference is missing or not rendered.");
      return;
    }

    // Ensure canvas content is drawn/rendered
    const imgData = canvas.toDataURL("image/png", 1.0); // Maximum quality PNG

    if (!imgData || imgData === "data:,") {
      console.error("Canvas is empty or not rendered properly.");
      return;
    }

    // Create a jsPDF instance
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: [canvas.width, canvas.height] // Match canvas dimensions
    });

    // Get canvas dimensions
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    // Scale the image to fit the PDF
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const aspectRatio = canvasWidth / canvasHeight;

    let imgWidth = pdfWidth;
    let imgHeight = pdfWidth / aspectRatio;

    if (imgHeight > pdfHeight) {
      imgHeight = pdfHeight;
      imgWidth = pdfHeight * aspectRatio;
    }

    // Add the image to the PDF
    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

    // Save the PDF
    pdf.save("certificate.pdf");
  };

  const downloadCertificateCME = () => {
    const canvas = canvasRef.current;

    if (!canvas) {
      console.error("Canvas reference is missing or not rendered.");
      return;
    }

    // Ensure canvas content is drawn/rendered
    const imgData = canvas.toDataURL("image/png", 1.0); // Maximum quality PNG

    if (!imgData || imgData === "data:,") {
      console.error("Canvas is empty or not rendered properly.");
      return;
    }

    // Create a jsPDF instance
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: [canvas.width, canvas.height] // Match canvas dimensions
    });

    // Get canvas dimensions
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    // Scale the image to fit the PDF
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const aspectRatio = canvasWidth / canvasHeight;

    let imgWidth = pdfWidth;
    let imgHeight = pdfWidth / aspectRatio;

    if (imgHeight > pdfHeight) {
      imgHeight = pdfHeight;
      imgWidth = pdfHeight * aspectRatio;
    }

    // Add the image to the PDF
    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

    // Save the PDF
    pdf.save("certificate CME.pdf");
  };
  return (
    <div className={`${styles.certificate_container}`}>
      <BootstrapCard
        className={`border-0 my-5 ${styles.certificate_card}`}
        cardBodyClassName={`${styles.card_body}`}
      >
        <p className={`${styles.title} font-B fsize-22 pb-1 my-0`}>
          {title}
        </p>
        <Container>
          {certificatesList?.map((certificate, index) => {
            console.log(
              "certificate?.hours_of_participation",
              certificate
            );
            return (
              <Row
                key={index}
                className={`my-3 ${styles.certificateConatiner}`}
              >
                <Col xs={12} md={12} lg={12}>
                  <Row className={`align-items-center py-3`}>
                    <Col xs={12} md={4} lg={6}>
                      <Row className={`align-items-center py-3`}>
                        <Col
                          xs={12}
                          md={12}
                          lg={6}
                          className={`d-flex justify-content-start ${styles.certificate_author}`}
                        >
                          <img
                            src={
                              certificate?.certificate
                                ?.hours_of_participation
                                ? CertificateCME
                                : CertificateMedblend
                            }
                            className={` ${styles.certificate_img}`}
                            alt="Certificate"
                          />
                        </Col>
                        <Col xs={12} md={12} lg={6}>
                          <div>
                            <p
                              className={` mb-1 ${styles.certificate_title} font-B`}
                            >
                              {certificate.book_title}
                              <span
                                className={`mx-1 mb-1 text-capitalize font-M fsize-10 ${styles.certificate_version}`}
                              >
                                &quot;version
                                {certificate.book_version}&quot;
                              </span>
                            </p>
                            <p className="clickable-book__author-name black-mid">
                              {writtenBy(
                                certificate?.author_name || "",
                                locale
                              )}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={12}
                      md={8}
                      lg={6}
                      className={`d-flex justify-content-start ${styles.certificate_author}`}
                    >
                      {/* continue_btn */}
                      <button
                        className={styles.showCertificate_btn}
                        onClick={() => {
                          setCurrentCertificate(certificate);
                          setOpenCertificateModal(true);
                        }}
                      >
                        {btn_text}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </Container>
      </BootstrapCard>
      {/* Certificate Modal */}
      <Modal
        open={openCertificateModal}
        onClose={handleCloseCertificate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <canvas
              ref={canvasRef}
              id="canvas"
              height="483px"
              width="700px"
            ></canvas>
          </Typography>
          <a
            className="cursor-pointer"
            onClick={() =>
              currentCertificate?.certificate?.hours_of_participation
                ? downloadCertificateCME()
                : downloadCertificate()
            }
          >
            Download
          </a>
        </Box>
      </Modal>
    </div>
  );
};

CertificatesList.propTypes = {
  certificatesList: PropTypes.array,
  btn_text: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string
};

export default CertificatesList;

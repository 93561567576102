/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  author_portal,
  customer_portal,
  editor_portal,
  program_director_portal
} from "../../network/apis";
import { updateBookProgress } from "../../store/Books/actions";
// Import styles
import styles from "./PDFViewer.module.scss";
import "./PDFViewerFull.scss";

const PDFViewerFull = ({
  singleBook,
  sendBookMarksToParent,
  isBookSubscribed,
  setCurrentPage,
  setPagesNumber
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAuthor = pathname.includes("author");
  const isEditor = pathname.includes("editor");
  const isProgram = pathname.includes("program");

  const [notes, setNotes] = useState(
    singleBook?.book_progress?.highlights_and_notes.notesProgress
  );

  let { id } = useParams();

  const clickPrintScreen = function (e) {
    var keyCode = e.keyCode ? e.keyCode : e.which;
    if (keyCode == 44) {
      stopPrntScr();
    }
  };

  const iframeRef = useRef(null);

  useEffect(() => {
    if (singleBook?.book_progress?.highlights_and_notes) {
      setNotes(
        singleBook?.book_progress?.highlights_and_notes.notesProgress
      );
    }
  }, [singleBook?.book_progress?.highlights_and_notes]);

  function stopPrntScr() {
    var inpFld = document.createElement("input");
    inpFld.setAttribute("value", ".");
    inpFld.setAttribute("width", "0");
    inpFld.style.height = "0px";
    inpFld.style.width = "0px";
    inpFld.style.border = "0px";
    document.body.appendChild(inpFld);
    inpFld.select();
    document.execCommand("copy");
    inpFld.remove(inpFld);
  }

  function AccessClipboardData() {
    try {
      window.clipboardData.setData("text", "Access   Restricted");
    } catch (err) {
      //
    }
  }
  useEffect(() => {
    document.addEventListener("keyup", clickPrintScreen);
    const interval = setInterval(AccessClipboardData(), 300);

    return () => {
      document.removeEventListener("keyup", clickPrintScreen);
      clearInterval(interval);
    };
  }, []);

  const [position, setPosition] = useState({
    selectionRegion: {
      left: "0",
      top: "0"
    }
  });

  useEffect(() => {
    if (singleBook?.pdf == null) return;
    const iframe = iframeRef.current;
    const iframeWindow = iframe?.contentWindow;

    // const goToPage = (pageNumber) => {
    //   const iframe = iframeRef.current;
    //   const iframeWindow = iframe?.contentWindow;
    //   if (iframeWindow) {
    //     iframeWindow.PDFViewerApplication.page = pageNumber;
    //     setCurrentPage(pageNumber);
    //     currentPageLocalComponent.current = pageNumber;
    //   }
    // };
    // goToPage(20);
    const handlePageChange = (pageNumber) => {
      // Perform any additional logic here based on the new page number
      if (!document.fullscreenElement) setCurrentPage(pageNumber);
      currentPageLocalComponent.current = pageNumber;
      if (pageNumber > 15 && !isBookSubscribed) {
        iframeWindow.PDFViewerApplication.page = 1;
        // makeUnSubscribedNotScoll();
      }
    };

    const onPageChangeInIFrame = (event) => {
      const pageNumber = event.pageNumber;
      handlePageChange(pageNumber);
      // setCurrentProgress();
    };

    const addPageChangeEvent = () => {
      const pdfViewer = iframeWindow.PDFViewerApplication.pdfViewer;
      pdfViewer.eventBus.on("pagechanging", onPageChangeInIFrame);
    };

    if (iframeWindow) {
      iframeWindow.onload = () => {
        const pdfViewerApplication =
          iframeWindow?.PDFViewerApplication;
        addPageChangeEvent();
        const pdfViewer = pdfViewerApplication?.pdfViewer;
        pdfViewer.eventBus.on(
          "pagerendered",
          () => {
            const pdfDocument =
              iframeWindow.PDFViewerApplication?.pdfDocument;
            const pdfOutlineViewer =
              iframeWindow.PDFViewerApplication?.pdfOutlineViewer;
            pdfDocument.getOutline().then((outline) => {
              sendBookMarksToParent(pdfOutlineViewer, outline);
            });
          },
          { once: true }
        );
        iframeWindow.oncontextmenu = (e) => {
          e.preventDefault();
        };
        let pdfWidth = iframe.contentDocument.body.scrollWidth;
        let containerWidth =
          iframe.contentDocument.getElementById(
            "mainContainer"
          ).scrollWidth;
        let initialScroll = (containerWidth - pdfWidth) / 2;
        iframeWindow.scrollTo(initialScroll, 0);
      };
    }
  }, [singleBook?.pdf]);

  const currentPageLocalComponent = useRef(0);

  const makeUnSubscribedNotScoll = () => {
    if (
      document.querySelector("#preventScroll .pdfViewer") &&
      !isBookSubscribed &&
      currentPageLocalComponent.current > 3 &&
      document.querySelector(
        "#preventScroll .rpv-default-layout__body"
      ).scrollTop > 1555
    ) {
      document
        .querySelector("#preventScroll .rpv-default-layout__body")
        .scrollTo(0, 0);
    }
  };

  const onPageChange = (e) => {
    setCurrentPage(e.currentPage + 1);
    currentPageLocalComponent.current = e.currentPage + 1;
    if (e.currentPage > 2) makeUnSubscribedNotScoll();
  };

  // eslint-disable-next-line no-unused-vars
  const setCurrentProgress = () => {
    dispatch(
      updateBookProgress({
        page: currentPageLocalComponent.current,
        singleBook,
        id
      })
    );
  };
  const backendUrl = (() => {
    let portal = customer_portal;
    if (isAuthor) portal = author_portal;
    if (isEditor) portal = editor_portal;
    if (isProgram) portal = program_director_portal;
    return `${process.env.REACT_APP_API_BASE_URL}${portal}`;
  })();
  return (
    <>
      <div
        onContextMenu={(e) => e.preventDefault()}
        id="preventScroll"
        className={` ${styles.fullWrapper} ${
          currentPageLocalComponent.current > 3 && !isBookSubscribed
            ? styles.preventScrollcls
            : null
        }`}
      >
        <Row>
          <Col
            xs={12}
            id={
              isBookSubscribed
                ? "book_viewer_complete"
                : "book_viewer"
            }
          >
            {singleBook?.pdf && (
              <div className="BookViewerDiv">
                <iframe
                  id="bookViewer"
                  title="PDF Viewer"
                  ref={iframeRef}
                  src={`/pdfjs-4.0.189-dist/web/viewer.html?file=${encodeURI(
                    singleBook?.pdf
                  )}&isBookSubscribed=${isBookSubscribed}&backendUrl=${backendUrl}`}
                  style={{ width: "100%", height: "100%" }}
                  itemType="application/pdf"
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

PDFViewerFull.propTypes = {
  singleBook: PropTypes.object,
  canvasLayer: PropTypes.object,
  annotationLayer: PropTypes.object,
  pageIndex: PropTypes.number,
  textLayer: PropTypes.object,
  sendBookMarksToParent: PropTypes.func,
  isBookSubscribed: PropTypes.bool,
  setCurrentPage: PropTypes.func,
  setPagesNumber: PropTypes.func
};

PDFViewerFull.defaultProps = {
  singleBook: {},
  sendBookMarksToParent: () => {},
  isBookSubscribed: false
};

export default PDFViewerFull;

const Play = () => {
  return (
    <svg style={{ hight: "30px", width: "30px" }}>
      <circle cx="41" cy="41" r="37" stroke="#fff" width="2"></circle>
      <polygon
        fill="none"
        stroke="#fff"
        points="32,25 32,58 60,42"
      ></polygon>
    </svg>
  );
};

export default Play;

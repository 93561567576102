import React from "react";
import { useIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import BookCover from "../../../../components/BookCover";
import { Card as BootstrapCard } from "../../../../components/Card/Card";
import Button from "../../../../components/Button";
import { writtenBy } from "../../../../utils/general";
import messages from "../../../../assets/locale/messages";
import { ROUTE_PATHS } from "../../../../utils/PathsNames";
import ModalClose from "../../../../assets/svgs/modal-close";
import { setPaymentMethod } from "../../../../store/Checkout/actions";
import styles from "./BookSubscription.module.scss";
import { setSubscribeBookSelected } from "../../../../store/Books/actions";
import History from "../../../../routes/History";

const BooksNameAndAuthor = ({ book, className }) => {
  const { title = "Unkown Title", author = "Unknoown Author" } =
    book || {};
  const { locale } = useIntl();

  return (
    <div className={className}>
      <p className={`${styles.book_title} fsize-16 mb-1`}>{title}</p>

      <p className="clickable-book__author-name black-mid">
        {writtenBy(author, locale)}
      </p>
    </div>
  );
};
BooksNameAndAuthor.propTypes = {
  book: PropTypes.object,
  className: PropTypes.string
};

const BookSubscriptionModal = ({
  singleBook,
  setSubscriptionModal
}) => {
  const { locale } = useIntl();
  const message = messages[locale];
  const dispatch = useDispatch();

  const subscriptionList = [
    {
      icon: (
        <FontAwesomeIcon
          icon={faCheck}
          className={`${styles.icon}`}
        />
      ),
      list: (
        <>
          <span className={`${styles.one_year}`}>12-Month</span>
          <span className={`mx-1`}>
            {" "}
            Unlimited Access to{" "}
            <span style={{ fontWeight: "bold" }}>
              {singleBook?.title}
            </span>{" "}
          </span>
        </>
      )
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={faCheck}
          className={`${styles.icon}`}
        />
      ),
      list: message.booksubscription.subscriptionSecondService
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={faCheck}
          className={`${styles.icon}`}
        />
      ),
      list: message.booksubscription.subscriptionThirdService
    }
  ];
  const subscritionButtons = [
    {
      isLink: true,
      slug: ROUTE_PATHS.checkout,
      text: message.buttons.paypalCheckout,
      className: `primary`,
      paymentMethod: "paypal"
    }
    // {
    //   isLink: true,
    //   slug: ROUTE_PATHS.checkout,
    //   text: message.buttons.stripeCheckout,
    //   className: `primary`,
    //   paymentMethod: "stripe"
    // }
  ];
  return (
    <>
      <Row className={`${styles.subscription_modal} border-0`}>
        <Col className="mx-auto px-5 pt-4 pb-5" lg={6}>
          <ModalClose
            className="cursor-pointer"
            onClick={() => setSubscriptionModal(false)}
          />
          <p className={`fsize-22 ${styles.subscritptionTitle} my-2`}>
            {message.booksubscription.subscritptionTitle}
          </p>
          <p
            className={`fsize-14 ${styles.subscritptionInfo} m-0 pb-4`}
          >
            {message.booksubscription.subscritptionInfo}
          </p>
          <div className="align-items-center pt-1">
            <BookCover size="xs" src={singleBook?.cover_photo} />
            <BooksNameAndAuthor
              book={singleBook}
              className={"my-4"}
            />
          </div>
        </Col>
        <Col className={`mx-auto ${styles.yearly_plan}`} lg={6}>
          <BootstrapCard
            className={`${styles.yearly_plan_card} h-80 border-0`}
          >
            <p
              className={`${styles.yearly_plan_card_title} fsize-20 m-0`}
            >
              {message.booksubscription.yearlyPlan}
            </p>
            <div className={`mb-5`}>
              {subscriptionList.map((item, index) => {
                return (
                  <div
                    className={`d-flex align-items-center ${styles.subscription_list}`}
                    key={index}
                  >
                    <span className={`${styles.icon}`}>
                      {item.icon}
                    </span>
                    <span
                      className={`mx-4 f-size-12 ${styles.listItem}`}
                    >
                      {item.list}
                    </span>
                  </div>
                );
              })}
            </div>
            <hr className={`${styles.hr} mb-4`} />
            <div
              className={`d-block d-md-flex w-100 justify-content-center justify-content-md-between align-items-center ${styles.payment_btns}`}
            >
              <div className="mb-1">
                <div
                  className={styles.subscriptionButton}
                  onClick={() => {
                    if (
                      JSON.parse(localStorage.getItem("user")) ||
                      JSON.parse(sessionStorage.getItem("user"))
                    ) {
                      dispatch(setPaymentMethod("paypal"));
                      sessionStorage.setItem(
                        "bookToBuy",
                        JSON.stringify(singleBook)
                      );
                      dispatch(setSubscribeBookSelected(singleBook));
                      // History.push(
                      //   `${ROUTE_PATHS.promocode}/${singleBook?.id}`
                      // );
                      History.push(
                        `${ROUTE_PATHS.bookCheckout}/${singleBook?.id}`
                      );
                    } else {
                      History.push(`${ROUTE_PATHS.signin}`);
                    }
                  }}
                >
                  <div>Subscribe for</div>
                  <div>${singleBook?.price}/ Year</div>
                </div>
              </div>
            </div>
          </BootstrapCard>
          <div className="mt-4">
            <span className={`fsize-10 ${styles.note}`}>
              {message.booksubscription.note}
            </span>
            <div
              className={`fsize-10 mx-1 ${styles.noteSubscription}`}
            >
              <span>
                • {message.booksubscription.firstNoteSubscription}
              </span>
              <span>
                • {message.booksubscription.secondNoteSubscription}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
BookSubscriptionModal.propTypes = {
  singleBook: PropTypes.object,
  setSubscriptionModal: PropTypes.func
};
export default BookSubscriptionModal;

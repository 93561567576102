import * as types from "./types";

const INITIAL_STATE = {
  list: [],
  meta: {},
  featured: {
    list: [],
    meta: {}
  },
  related: {
    list: [],
    meta: {}
  },
  filters: {
    search_term: null,
    category_id: null,
    sorting_term: null
  },
  singleBook: null,
  singleBookSupplementaries: [],
  SupplementariesProgress: [],
  subscibedBooks: [],
  subscibedBooksList: [],
  subscibedBooksMeta: {},
  subscibedBooksIds: [],
  certificateList: [],
  singleCertificate: {},
  certificateListMeta: {},
  subscribeBookSelected:
    JSON.parse(sessionStorage.getItem("bookToBuy")) || null,
  singleBookQuizzes: [],
  singleBookTests: [],
  bookSingleTest: null,
  bookSingleTestCanceled: {},

  institutionBooksList: [],
  institutionBooksMeta: {},
  answerSingleTest: null,
  quiz: null,

  currentSunscriptionLoader:
    +localStorage.getItem("currentSunscriptionLoader") || null,

  notes: [],
  programs: [],
  currentBookPage: 1
};

const booksReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_CURRENT_BOOK_PAGE:
      return {
        ...state,
        currentBookPage: action.payload.currentBookPage
      };
    case types.SET_BOOKS:
      return {
        ...state,
        list: action.payload.list,
        meta: action.payload.meta
      };
    case types.SET_USER_PROGRAMS:
      return {
        ...state,
        programs: action.payload.programs
      };

    case types.SET_BOOKS_FEATURED:
      return {
        ...state,
        featured: {
          list: action.payload.list,
          meta: action.payload.meta
        }
      };
    case types.SET_CURRENT_SUBSCRIPTION_LOADER:
      if (action.payload.id) {
        localStorage.setItem(
          "currentSunscriptionLoader",
          action.payload.id
        );
      } else {
        localStorage.removeItem("currentSunscriptionLoader");
      }
      return {
        ...state,
        currentSunscriptionLoader: action.payload.id
      };
    case types.SET_BOOKS_FILTERS:
      return {
        ...state,
        filters: {
          search_term: action.payload.search_term,
          category_id: action.payload.category_id,
          sorting_term: action.payload.sorting_term
        }
      };
    case types.SET_RELATED_BOOKS:
      return {
        ...state,
        related: {
          list: action.payload.list,
          meta: action.payload.meta
        }
      };
    //  TODO: find out where we need to add this list and rename
    case types.GET_SUBSCRIBED_BOOKS_RESPONSE:
      return {
        ...state,
        subscibedBooksList: action.payload.list,
        subscibedBooksMeta: action.payload.meta
      };
    case types.SET_SINGLE_BOOK:
      return { ...state, singleBook: action.payload };
    case types.SET_SINGLE_BOOK_SUPPLEMENTRAIES:
      return { ...state, singleBookSupplementaries: action.payload };
    case types.SET_SUBSCRIBE_BOOK_SELECTED:
      return { ...state, subscribeBookSelected: action.payload };
    case types.SET_SUBSCIBED_BOOKS_ACTION:
      return { ...state, subscibedBooks: action.payload };
    case types.SET_SUBSCRIBED_BOOKS_IDS:
      return { ...state, subscibedBooksIds: action.payload };
    case types.ADD_SUBSCIBED_BOOK_ACTION:
      return {
        ...state,
        subscibedBooks: [...state.subscibedBooks, action.payload]
      };
    case types.SET_BOOK_QUIZZES:
      return {
        ...state,
        singleBookQuizzes: action.payload
      };
    case types.SET_CERTAIN_QUIZ:
      return {
        ...state,
        quizDetails: action.payload
      };
    case types.CREATE_BOOK_TESTS_STATUS:
      return {
        ...state,
        bookSingleTestCanceled: action.payload
      };
    case types.UPDATE_BOOK_TESTS_STATUS:
      // eslint-disable-next-line no-case-declarations
      const updatedObject = { ...state.bookSingleTestCanceled };
      updatedObject[action.payload.id] = {
        ...updatedObject[action.payload.id],
        [action.payload.type]: true
      };
      return {
        ...state,
        bookSingleTestCanceled: { ...updatedObject }
      };
    case types.REMOVE_UPDATE_BOOK_TESTS_STATUS:
      // eslint-disable-next-line no-case-declarations
      const removeUpdatedObject = { ...state.bookSingleTestCanceled };
      removeUpdatedObject[action.payload.id] = {
        ...removeUpdatedObject[action.payload.id],
        [action.payload.type]: false
      };
      return {
        ...state,
        bookSingleTestCanceled: { ...removeUpdatedObject }
      };
    case types.GET_BOOK_TESTS_RESPONSE:
      return {
        ...state,
        singleBookTests: action.payload
      };
    case types.GET_BOOK_SINGLE_TEST_RESPONSE:
      return {
        ...state,
        bookSingleTest: action.payload
      };

    case types.ANSWER_BOOK_SINGLE_TEST_RESPONSE:
      return {
        ...state,
        answerSingleTest: action.payload
      };

    case types.GET_CERTIFICATE_RESPONSE:
      return {
        ...state,
        certificateList: action.payload.list,
        certificateListMeta: action.payload.meta
      };
    case types.GET_SINGLE_CERTIFICATE_RESPONCE:
      return {
        ...state,
        singleCertificate: action.payload.singleCertificate
      };
    case types.GET_INSTITUTION_BOOKS_RESPONSE:
      return {
        ...state,
        institutionBooksList: action.payload.list,
        institutionBooksMeta: action.payload.meta
      };
    case types.SET_BOOK_SUPPLEMENTARY_PROGRESS:
      return {
        ...state,
        SupplementariesProgress: action.payload
      };
    case types.SET_EDITOR_NOTES:
      return {
        ...state,
        notes: action.payload.notes
      };

    default:
      return state;
  }
};

export default booksReducer;

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { renderModalPreview } from ".";
import openTab from "../../assets/svgs/open-tab.svg";
import { SET_OPEN_MATERIAL_MODAL } from "../../store/Utilities/types";
import { KEEP_LINE_SPACES } from "../../utils/Patterns";
import styles from "./MaterialPreview.module.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTE_PATHS } from "../../utils/PathsNames";

// { details, type, src, dicomImages }
function MaterialModal() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const isProgram = pathname.includes("program");
  const isAuthor = pathname.includes("author");
  // console.log("material-modal is Author: " + isAuthor);
  const [newTabIconHovered, setNewTabIconHovered] =
    React.useState(false);
  const {
    showMaterialModal,
    materialData: { details, type, src, dicomImages }
  } = useSelector((state) => state.utilities);
  const dispatch = useDispatch();
  const setOpenModal = (open) => {
    dispatch({
      type: SET_OPEN_MATERIAL_MODAL,
      payload: open
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4
  };

  const { messages } = useIntl();
  const {
    shared,
    pdfViewer: { openNewTab }
  } = messages;
  return (
    <>
      <Modal
        open={showMaterialModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="suplementaryViewModal">
          <Box sx={style}>
            <div
              className={styles.xBtn}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            {renderModalPreview({
              videoWithControls: true,
              type,
              src,
              dicomImages
            })}
            {details?.material_type === "video" && (
              <Typography sx={{ marginTop: "15px" }}>
                {messages.watchNow}
              </Typography>
            )}
            <Typography
              sx={{
                marginTop: "10px",
                fontWeight: "bold",
                fontSize: "17px"
              }}
              className="text-primary fsize-18 bold mt-4 "
            >
              {details?.material_title}
            </Typography>
            <p
              className={`lh-lg ${styles.description} text-gray`}
              dangerouslySetInnerHTML={{
                __html: details?.diagnosis?.replace(
                  KEEP_LINE_SPACES,
                  "<br />"
                )
              }}
            ></p>
            <p
              className={`lh-lg ${styles.description}`}
              dangerouslySetInnerHTML={{
                __html: details?.description?.replace(
                  KEEP_LINE_SPACES,
                  "<br />"
                )
              }}
            ></p>
            <div className="d-flex justify-content-end">
              <a
                className="cursor-pointer"
                onClick={() => {
                  localStorage.setItem(
                    "supplementary",
                    JSON.stringify(details)
                  );
                  window.open(
                    `${
                      isAuthor
                        ? ROUTE_PATHS.authorsSupplementary
                        : isProgram
                        ? ROUTE_PATHS.programsSupplementary
                        : ROUTE_PATHS.supplementary
                    }/${details.id}/${id}`,
                    "_blank"
                  );
                }}
                rel="noreferrer"
              >
                <span
                  className={`${styles.iconOpentext} mx-2`}
                  style={{
                    visibility: newTabIconHovered
                      ? "visible"
                      : "hidden"
                  }}
                >
                  {openNewTab}
                </span>
                <img
                  src={openTab}
                  className={styles.iconOpen}
                  height="30px"
                  onMouseEnter={() => {
                    setNewTabIconHovered(true);
                  }}
                  onMouseLeave={() => {
                    setNewTabIconHovered(false);
                  }}
                />
              </a>
            </div>
          </Box>
        </div>
      </Modal>
    </>
  );
}

MaterialModal.propTypes = {
  details: PropTypes.object,
  type: PropTypes.string,
  src: PropTypes.string,
  dicomImages: PropTypes.arrayOf(PropTypes.string),
  shared: PropTypes.object
};

export default MaterialModal;

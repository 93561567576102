import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Col, Container, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../components/Input";
import Button from "../../components/Button";

import GoogleButton from "../../components/GoogleButton";
import Or from "../../components/Or";
import medblendLogo from "../../assets/images/logo.png";
import {
  loginRequest,
  loginThenVerify
} from "../../store/Auth/actions";
import { LOGIN_SCHEMA } from "./validations";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessge";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import { useQuery } from "../../hooks/useQuery";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const LoginPage = ({ isVerification }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const login = useSelector((state) => state.auth.login);
  const { messages, locale } = useIntl();
  const {
    login: { header, isHaveAccount, createAccount },
    inputs: { email, password, remeberMe },
    buttons: { signIn },
    forgotPassword: { link },
    copyRights,
    contactUs
  } = messages;

  const setPasswordStatus = () => setIsShowPassword(!isShowPassword);
  const { pathname } = useLocation();
  const isEditor = pathname.includes("editor");
  const isAuthor = pathname.includes("author");
  const isProgram = pathname.includes("program");
  const handleSubmit = (data) => {
    const clonedData = { ...data, isAuthor, isEditor };
    if (isVerification && query.get("token")) {
      dispatch(
        loginThenVerify({
          clonedData,
          token: query.get("token") || localStorage.getItem("token")
        })
      );
      localStorage.removeItem("token");
    } else {
      dispatch(loginRequest(clonedData));
    }
  };

  return (
    <Container>
      <Row>
        <Col className="mx-auto" lg={10}>
          <h3 className="mb-4 w-75">{header}</h3>
          <GoogleButton type="signin" />
          <Or />
          <Formik
            initialValues={{
              email: null,
              password: null,
              remeberMe: false
            }}
            validationSchema={LOGIN_SCHEMA(locale)}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <Input name="email" label={email} />
                <Input
                  inputIcon={isShowPassword ? "Hide" : "Show"}
                  type={!isShowPassword ? "password" : "text"}
                  label={password}
                  name="password"
                  onIconClick={setPasswordStatus}
                />
                <ErrorMessage message={login?.message} />
                <div className="mb-4">
                  <Link
                    to={ROUTE_PATHS.forgetPassword}
                    className="font-M fsize-12"
                  >
                    {" "}
                    {link}
                  </Link>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <Button text={signIn} className="primary-lg" />
                </div>
              </Form>
            )}
          </Formik>
          <hr />
          <div className="fsize-12 mb-sm-20 mb-md-40 font-M">
            {isHaveAccount}
            <Link
              to={ROUTE_PATHS.registration}
              className="text-secondary font-M fsize-12"
            >
              {" "}
              {createAccount}
            </Link>
          </div>
          <div className="d-flex align-items-center justify-content-between text-gray">
            <img
              src={medblendLogo}
              alt="medblend-logo"
              width="50px"
            />
            <p className="m-0 fsize-10">{copyRights}</p>
            <Link className="fsize-10" to={ROUTE_PATHS.contactUs}>
              {contactUs}
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

LoginPage.propTypes = {
  isVerification: PropTypes.bool
};

LoginPage.defaultProps = {
  isVerification: false
};

export default LoginPage;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { Typography } from "@mui/material";
import {
  getSingleBookSupplementariesAction,
  updateBookSupplementaryProgress,
  getBookSupplementaryProgress,
  getBookTestsRequest
} from "../../store/Books/actions";
import DicomViewer from "../../components/DicomViewer";
import styles from "./SupplementaryDisplay.module.scss";
import { ImgDisplay } from "../../hooks/ImgDisplay";
import { KEEP_LINE_SPACES } from "../../utils/Patterns";
import { Container, Row } from "react-bootstrap";
import { ROUTE_PATHS } from "../../utils/PathsNames";
import { useHistory } from "react-router-dom";
import libraryVideoSVG from "../../assets/svgs/material-symbols-light_video-library-outline.svg";
import Arrow from "../../assets/svgs/fluent_ios-arrow-24-regular.svg";
import BookIcon from "../../assets/svgs/lets-icons_book-open-light.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PlayDisabledIcon from "@mui/icons-material/PlayDisabled";
import { useIntl } from "react-intl";
function SupplementaryDisplay() {
  const { messages } = useIntl();
  const {
    SupplementaryDisplay: {
      lectures,
      errorVideo,
      errorSupplementary,
      objectSupplementaryError,
      previous,
      next,
      noPreviousSupplementary,
      noNextSupplementary
    },
    books: { book }
  } = messages;
  const history = useHistory();
  const { id, bookId } = useParams();
  const { pathname } = useLocation();
  const isProgram = pathname.includes("program");
  const isAuthor = pathname.includes("author");
  const dispatch = useDispatch();
  const {
    singleBookSupplementaries,
    SupplementariesProgress,
    singleBookTests
  } = useSelector((state) => state.books);

  // Use state to persist `singleSupplementary` across renders
  const [singleSupplementary, setSingleSupplementary] = useState(
    () => {
      const localData = JSON.parse(
        localStorage.getItem("supplementary")
      );
      return localData || null;
    }
  );
  const sortedTests = singleBookTests.sort(
    (a, b) => a.pre_page - b.pre_page
  );

  const chapterSupplementaries = sortedTests.map((test) => {
    return singleBookSupplementaries.filter(
      (video) =>
        video.book_page >= test.pre_page &&
        video.book_page <= test.post_page
    );
  });
  const [chapterIndex, setChapterIndex] = useState(null);
  const [nextSupplementary, setNextSupplementary] = useState({});
  const [previousSupplementary, setPreviousSupplementary] = useState(
    {}
  );
  console.log("chapterIndex", chapterIndex);
  function chapterNumberfromIndex(foundSupplementary) {
    if (
      !chapterSupplementaries ||
      chapterSupplementaries.length === 0
    ) {
      return <div>Loading...</div>;
    }

    const index = chapterSupplementaries?.findIndex((chapter) =>
      chapter?.some(
        (supplementary) =>
          supplementary?.book_page === foundSupplementary?.book_page
      )
    );

    console.log("Calculated index:", index);
    return index !== -1 ? index : null;
  }

  function getNextSupplementary() {
    let nextSuplementary = {};
    if (Array.isArray(singleBookSupplementaries)) {
      const allSubsForBook = singleBookSupplementaries
        .map((sub) => sub?.supplementary_materials || [])
        .flat();

      let currentSupplementIndex = -1;
      for (let i = 0; i < allSubsForBook.length; i++) {
        if (singleSupplementary?.id === allSubsForBook[i]?.id) {
          currentSupplementIndex = i;
        }
      }

      nextSuplementary = allSubsForBook[currentSupplementIndex + 1];
    }
    return nextSuplementary;
  }
  function getPreviousSupplementary() {
    let previousSuplementary = {};
    if (Array.isArray(singleBookSupplementaries)) {
      const allSubsForBook = singleBookSupplementaries
        .map((sub) => sub?.supplementary_materials || [])
        .flat();

      let currentSupplementIndex = -1;
      for (let i = 0; i < allSubsForBook.length; i++) {
        if (singleSupplementary?.id === allSubsForBook[i]?.id) {
          currentSupplementIndex = i;
        }
      }

      previousSuplementary =
        allSubsForBook[currentSupplementIndex - 1];
    }
    return previousSuplementary;
  }

  useEffect(() => {
    if (
      Array.isArray(singleBookSupplementaries) &&
      chapterSupplementaries.length > 0
    ) {
      const allSubsForBook = singleBookSupplementaries
        .map((sub) => sub?.supplementary_materials || [])
        .flat();

      const foundSupplementary = allSubsForBook.find(
        (sub) => +sub?.id === +id
      );

      if (foundSupplementary) {
        setSingleSupplementary(foundSupplementary);

        const index = chapterNumberfromIndex(foundSupplementary);
        setChapterIndex(index !== null ? index : 0);
        const next = getNextSupplementary();
        const previous = getPreviousSupplementary();

        // Only update the state if the values have changed
        setNextSupplementary((prev) => (next !== prev ? next : prev));
        setPreviousSupplementary((prev) =>
          previous !== prev ? previous : prev
        );
      } else if (!singleSupplementary) {
        const localData = JSON.parse(
          localStorage.getItem("supplementary")
        );
        setSingleSupplementary(localData);
      }
    }
  }, [
    id,
    singleBookSupplementaries,
    chapterSupplementaries,
    dispatch
  ]);

  console.log("chapterSupplementaries", chapterSupplementaries);

  // Fetch supplementary data only once on mount or when `bookId` changes
  useEffect(() => {
    if (bookId != null) {
      dispatch(
        getSingleBookSupplementariesAction({
          book_id: bookId,
          isProgram,
          isAuthor
        })
      );
      dispatch(getBookTestsRequest({ book_id: bookId }));
    }
  }, [bookId]);

  useEffect(() => {
    if (
      singleSupplementary?.material_type === "video" &&
      !isProgram &&
      !isAuthor
    ) {
      dispatch(getBookSupplementaryProgress({ book_id: +bookId }));
    }
  }, [singleSupplementary, bookId]);

  const handleProgress = (state) => {
    if (
      !SupplementariesProgress[
        +singleSupplementary.supplementary_material_id
      ] &&
      state.played >= 0.9 &&
      !isProgram &&
      !isAuthor
    ) {
      dispatch(
        updateBookSupplementaryProgress({
          book_id: +bookId,
          supplementary_material_id:
            +singleSupplementary.supplementary_material_id,
          watched: true
        })
      );
      dispatch(getBookSupplementaryProgress({ book_id: +bookId }));
    }
  };

  const renderPreview = (videoWithControls) => {
    switch (singleSupplementary?.material_type) {
      case "video":
        return (
          <div className={styles.video}>
            {singleSupplementary?.url?.[0] ? (
              <ReactPlayer
                className={styles.preview}
                url={singleSupplementary?.url?.[0]}
                width="100%"
                height="100%"
                controls={videoWithControls}
                config={{
                  file: { attributes: { controlsList: "nodownload" } }
                }}
                onProgress={handleProgress}
              />
            ) : (
              <div className={styles.videoWrapper}>
                <PlayDisabledIcon />
                <p>{errorVideo}</p>
              </div>
            )}
          </div>
        );
      case "image":
        return (
          <div className={styles.item}>
            <ImgDisplay
              src={singleSupplementary?.url?.[0]}
              className={styles.preview}
            />
          </div>
        );
      case "DICOM":
        return (
          <div className={styles.item}>
            <DicomViewer
              imageIds={singleSupplementary?.url || []}
              className={styles.preview}
            />
          </div>
        );
      default:
        return <p>{errorSupplementary}</p>;
    }
  };
  const navigatToLecturesPage = () => {
    const route = isAuthor
      ? `${ROUTE_PATHS.authorsBookAllVideos}/${bookId}`
      : isProgram
      ? `${ROUTE_PATHS.programsBookAllVideos}/${bookId}`
      : `${ROUTE_PATHS.bookAllVideos}/${bookId}`;
    history.push(route);
  };

  if (!singleSupplementary) {
    return <>{objectSupplementaryError}</>;
  }

  const goToBookView = () => {
    let route = isAuthor
      ? `${ROUTE_PATHS.authorsBookViewer}/${bookId}`
      : isProgram
      ? `${ROUTE_PATHS.programsBookViewer}/${bookId}`
      : `${ROUTE_PATHS.bookViewer}/${bookId}`;
    history.push(route);
  };
  return (
    <div
      style={{ minHeight: "400px", height: "300px", padding: "10px" }}
      className="containerDiv"
    >
      <Container>
        <Row>
          <button
            className={`${styles.linkLectures}`}
            onClick={navigatToLecturesPage}
          >
            <img
              className={`${styles.libraryVideologo}`}
              src={libraryVideoSVG}
            ></img>
            {lectures}
          </button>
          <Typography
            sx={{
              marginTop: "10px",
              fontWeight: "bold",
              fontSize: "17px"
            }}
          >
            {`Chapter ${chapterIndex + 1} : ${
              singleSupplementary.material_title
            }`}
          </Typography>
          {renderPreview(true)}
          <div className={styles.SupplementaryLinksContainer}>
            {previousSupplementary ? (
              <div className={styles.previousSupplementaryLink}>
                <div
                  className={styles.SupplementaryLink}
                  style={{ justifyContent: "flex-start" }}
                >
                  <img src={Arrow} alt="Arrow Left" />
                  <Link
                    to={`${ROUTE_PATHS.supplementary}/${previousSupplementary.id}/${bookId}`}
                  >
                    {previous}
                  </Link>
                </div>
                <p>
                  {previousSupplementary?.material_title ||
                    noPreviousSupplementary}
                </p>
              </div>
            ) : (
              <div style={{ width: "100px" }}></div>
            )}
            <button
              className={styles.bookButton}
              onClick={goToBookView}
            >
              <img src={BookIcon} width={25} />
              {book}
            </button>
            {nextSupplementary ? (
              <div className={styles.nextSupplementaryLink}>
                <div
                  className={styles.SupplementaryLink}
                  style={{ justifyContent: "flex-end" }}
                >
                  <Link
                    to={`${ROUTE_PATHS.supplementary}/${nextSupplementary.id}/${bookId}`}
                  >
                    {next}
                  </Link>
                  <img
                    src={Arrow}
                    alt="Arrow Right"
                    style={{ transform: "rotate(180deg)" }}
                  />
                </div>
                <p>
                  {nextSupplementary?.material_title ||
                    noNextSupplementary}
                </p>
              </div>
            ) : (
              <div style={{ width: "100px" }}></div>
            )}
          </div>

          {/*  */}
          {singleSupplementary?.diagnosis && (
            <p
              className={`lh-lg ${styles.description} text-gray`}
              dangerouslySetInnerHTML={{
                __html: singleSupplementary?.diagnosis.replace(
                  KEEP_LINE_SPACES,
                  "<br />"
                )
              }}
            ></p>
          )}
          <p
            className={`lh-lg ${styles.description}`}
            dangerouslySetInnerHTML={{
              __html: singleSupplementary.description.replace(
                KEEP_LINE_SPACES,
                "<br />"
              )
            }}
          ></p>
        </Row>
      </Container>
    </div>
  );
}

export default SupplementaryDisplay;

import React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { ROUTE_PATHS } from "../utils/PathsNames";
import history from "./History";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
// ========= Components =========
import BookProfile from "../containers/Books/BookProfile";
import BooksListing from "../containers/Books/BooksListing/BooksListing";
import BookViewer from "../containers/Books/BookViewer";
import Checkout from "../containers/Checkout";
import ForgetPassword from "../containers/ForgetPassword";
import Home from "../containers/Home/Home";
import Verify from "../containers/Home/Verify";
import Registration from "../containers/Registration";
import SupplementaryDisplay from "../containers/SupplementaryDisplay";
import UserSubscribedBookList from "../containers/User/BookList";
import Certificates from "../containers/User/Certificates";
import ChangePassword from "../containers/User/ChangePassword";
import ProfileInfo from "../containers/User/ProfileInfo";
import SubscriptionManagement from "../containers/User/SubscriptionManagement";
import Verification from "../containers/Verification";
// import AllBookSupplementaries from "../containers/Books/AllBookSupplementaries";
import AllBookTests from "../containers/Books/AllBookTests";
import AllBookVideos from "../containers/Books/AllBookVideos";
import EditorBookNotes from "../containers/Books/EditorBookNotes/EditorBookNotes";
import ContactUs from "../containers/ContactUs";
import EditOrAddNewTopic from "../containers/EditOrAddNewTopic";
import FinalTestWraper from "../containers/FinalTest/FinalTestWraper";
import LoginPage from "../containers/Login/LoginPage";
import DetialsTopic from "../containers/OldTopic";
import Program from "../containers/Program/Program";
import Promocode from "../containers/Promocode";
import QuizDisplay from "../containers/QuizDisplay";
import StudentProgress from "../containers/StudentProgress";
import StudentsList from "../containers/StudentsList";
import TestDisplay from "../containers/TestDisplay";
import Topics from "../containers/Topics";
import InstitutionBooksList from "../containers/User/InstitutionBooksList";
import SubscriberRoute from "./SubscriberRoute";
import BookCheckout from "../containers/BookCheckout/BookCheckout";
const Routes = (
  <Router history={history}>
    <Switch>
      {/* <Route
        component={ViewScoreFinalTest}
        path={`${ROUTE_PATHS.FinalTestScore}/:book_id`}
        exact
      />
      <Route
        component={FinalTest}
        path={`${ROUTE_PATHS.FinalTest}/:mode?/:book_id`}
        exact
      /> */}
      <Route
        component={FinalTestWraper}
        path={`${ROUTE_PATHS.FinalTest}/:mode/:book_id/:test_view_mode?`}
        exact
      />
      <Route
        component={FinalTestWraper}
        path={`${ROUTE_PATHS.programsFinalTest}/:mode/:book_id/:test_view_mode?`}
        exact
      />
      <Route
        component={Home}
        path={[
          ROUTE_PATHS.home,
          ROUTE_PATHS.authorsHome,
          ROUTE_PATHS.programHome,
          ROUTE_PATHS.editorsHome
        ]}
        exact
      />{" "}
      <Route
        component={StudentsList}
        path={ROUTE_PATHS.StudentsList}
        exact
      />
      <Route
        component={EditOrAddNewTopic}
        path={ROUTE_PATHS.EditOrAddNewTopic}
        exact
      />
      <Route
        component={DetialsTopic}
        path={[
          ROUTE_PATHS.DetialsTopic,
          ROUTE_PATHS.DirectorDetialsTopic
        ]}
        exact
      />
      <Route
        component={Topics}
        path={[
          ROUTE_PATHS.Topics,
          `${ROUTE_PATHS.StudentsTopics}/:programId`
        ]}
        exact
      />
      <Route
        component={StudentProgress}
        path={`${ROUTE_PATHS.StudentProgress}/:studentId`}
        exact
      />
      <Route
        component={Verify}
        path={ROUTE_PATHS.missingVerification}
        exact
      />
      <PublicRoute
        component={Registration}
        path={`${ROUTE_PATHS.registration}`}
        exact
      />
      <Route
        component={ContactUs}
        path={`${ROUTE_PATHS.contactUs}`}
        exact
      />
      <Route
        component={Verification}
        path={`${ROUTE_PATHS.verification}`}
      />
      <Route
        component={BookProfile}
        path={[
          `${ROUTE_PATHS.bookProfile}/:id`,
          `${ROUTE_PATHS.programBookProfile}/:id`
        ]}
      />
      <Route
        component={BooksListing}
        path={`${ROUTE_PATHS.books}/:listType?/:bookId?`}
      />
      <Route
        component={InstitutionBooksList}
        path={`${ROUTE_PATHS.institutionBooks}`}
      />
      <Route
        component={BookViewer}
        path={[
          `${ROUTE_PATHS.bookViewer}/:id`,
          `${ROUTE_PATHS.authorsBookViewer}/:id`,
          `${ROUTE_PATHS.editorBookViewer}/:id`,
          `${ROUTE_PATHS.programsBookViewer}/:id`
        ]}
      />
      {/* <Route
        component={BookViewer}
        path={`${ROUTE_PATHS.authorsBookViewer}/:id`}
      />
      <Route
        component={BookViewer}
        path={`${ROUTE_PATHS.editorBookViewer}/:id`}
      />
      <Route
        component={BookViewer}
        path={`${ROUTE_PATHS.programsBookViewer}/:id`}
      /> */}
      <SubscriberRoute
        component={SupplementaryDisplay}
        path={`${ROUTE_PATHS.supplementary}/:id/:bookId`}
      />
      <SubscriberRoute
        component={SupplementaryDisplay}
        path={`${ROUTE_PATHS.programsSupplementary}/:id/:bookId`}
      />
      <SubscriberRoute
        component={SupplementaryDisplay}
        path={`${ROUTE_PATHS.authorsSupplementary}/:id/:bookId`}
      />
      <Route
        component={ProfileInfo}
        path={`${ROUTE_PATHS.profileInfo}/:id`}
      />
      <PrivateRoute
        component={ChangePassword}
        path={[
          `${ROUTE_PATHS.changePassword}`,
          `${ROUTE_PATHS.authorsChangePassword}`,
          `${ROUTE_PATHS.editorsChangePassword}`,
          `${ROUTE_PATHS.programsChangePassword}`
        ]}
      />
      <Route
        component={ForgetPassword}
        path={`${ROUTE_PATHS.forgetPassword}`}
      />
      <Route
        component={Certificates}
        path={`${ROUTE_PATHS.certificates}`}
      />
      <PrivateRoute
        component={Checkout}
        path={`${ROUTE_PATHS.checkout}`}
      />
      <PrivateRoute
        component={Program}
        path={`${ROUTE_PATHS.programHome}`}
        exact
      />
      <PrivateRoute
        component={Promocode}
        path={`${ROUTE_PATHS.promocode}/:id`}
      />
      <PrivateRoute
        component={BookCheckout}
        path={`${ROUTE_PATHS.bookCheckout}/:id`}
      />
      <PrivateRoute
        component={SubscriptionManagement}
        path={`${ROUTE_PATHS.subscriptionManagement}`}
      />
      <PrivateRoute
        component={UserSubscribedBookList}
        path={`${ROUTE_PATHS.userBookList}`}
      />
      {/* <Route
        component={AllBookSupplementaries}
        path={`${ROUTE_PATHS.bookAllSupplementary}/:id`}
      /> */}
      <Route
        component={AllBookVideos}
        path={[
          `${ROUTE_PATHS.bookAllVideos}/:id`,
          `${ROUTE_PATHS.programsBookAllVideos}/:id`,
          `${ROUTE_PATHS.authorsBookAllVideos}/:id`
        ]}
      />
      <Route
        component={AllBookTests}
        path={[
          `${ROUTE_PATHS.bookAllTests}/:id`,
          `${ROUTE_PATHS.programsBookAllTests}/:id`,
          `${ROUTE_PATHS.authorsBookAllTests}/:id`
        ]}
      />
      <SubscriberRoute
        component={TestDisplay}
        path={`${ROUTE_PATHS.testdisplay}/:id/:testTypeString/:bookId`}
      />
      <SubscriberRoute
        component={TestDisplay}
        path={`${ROUTE_PATHS.programsTestDisplay}/:id/:testTypeString/:bookId`}
      />
      <SubscriberRoute
        component={QuizDisplay}
        path={`${ROUTE_PATHS.quizdisplay}/:id/:bookId/:bookPage`}
      />
      <SubscriberRoute
        component={QuizDisplay}
        path={`${ROUTE_PATHS.programsQuizDisplay}/:id/:bookId/:bookPage`}
      />
      <Route
        component={EditorBookNotes}
        path={`${ROUTE_PATHS.editorNotes}/:bookId`}
      />
      <PublicRoute
        component={LoginPage}
        path={[
          `${ROUTE_PATHS.signin}`,
          `${ROUTE_PATHS.programsLogin}`,
          `${ROUTE_PATHS.authorLogin}`,
          `${ROUTE_PATHS.editorLogin}`
        ]}
        exact
      />
      <Redirect from="*" to="/" />
    </Switch>
  </Router>
);

export default Routes;
